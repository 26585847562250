import React from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import BackgroundImageSection from "../components/BackgroundImageSection"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 640,
    color: "white",
    paddingTop: 100,
    paddingBottom: 100,
  },
  buttonPosition: {
    marginTop: 20,
  },
  buttonPositionRight: {
    marginTop: 20,
    marginLeft: 20,
  },
}))

export default function Types() {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Pocetna" />
      <BackgroundImageSection
        heading="Prevoz robe, stvari i selidbe"
        text={
          <div>
            <Typography variant="body2" gutterBottom>
              <p>
                Nudimo profesionalne usluge prevoza robe i kompletnu uslugu
                selidbe na području Beograda, širom Srbije i inostranstvu.
              </p>
              <p>
                Sa ponosom se možemo pohvaliti našim profesionalnim vozačima sa
                dugogodišnjim iskustvom i pouzdanim vozilima koji su zaslužni za
                brz i pouzdan transport.
              </p>
              <p>
                Na raspolaganju imamo vozni park koji sadrži pickup, kombi za
                teretni prevoz, kamion i šleper uz pomoć kojih nudimo širok
                dijapazon usluga.
              </p>
            </Typography>
            <Button
              variant="outlined"
              href="/services"
              className={classes.buttonPosition}
            >
              Usluge koje nudimo
            </Button>
            <Button
              variant="outlined"
              href="tel:0616618497"
              className={classes.buttonPositionRight}
            >
              Pozovite nas
            </Button>
          </div>
        }
        buttonText=""
      />
    </Layout>
  )
}
